import React, { useReducer, useState } from 'react'
import "./navbar.scss"
import { motion } from "framer-motion"
import { NavLink, Router, Route } from "react-router-dom";
import HomeLower from '../Home/HomeLowerSection/HomeLower';
import Footer from '../Footer/Footer';
import About from '../About/About';
import OurCourses from '../OurCourses/OurCourses';
import Facility from '../Facilities/Facility';
import OurLabs from '../OurLabs/OurLabs';
import ContactUs from '../ContactUs/ContactUs';
import PhotoGallery from '../PhotoGallery/PhotoGallery';
import EnquirySection from '../Enquiry/EnquirySection';
import { HashLink } from 'react-router-hash-link'
import Nav from './Nav';
import BackSlider from '../Top/BackSlider';
import Instructor from '../InstructorSection/Instructor';

const reducer = (state, action) => {
    switch (action.type) {
        case "Home":
            return { ...state, Home: true, About_Us: false, Entrance_Exams: false, Our_Courses: false, Instructor_Details: false, Facilities: false, Our_Labs: false, Photo_Gallary: false, Enquiry: false, Contact_Us: false };
        case "About_Us":
            return { ...state, Home: false, About_Us: true, Entrance_Exams: false, Our_Courses: false, Instructor_Details: false, Facilities: false, Our_Labs: false, Photo_Gallary: false, Enquiry: false, Contact_Us: false }
        case "Entrance_Exams":
            return { ...state, Home: false, About_Us: false, Entrance_Exams: true, Our_Courses: false, Instructor_Details: false, Facilities: false, Our_Labs: false, Photo_Gallary: false, Enquiry: false, Contact_Us: false }
        case "Our_Courses":
            return { ...state, Home: false, About_Us: false, Entrance_Exams: false, Our_Courses: true, Instructor_Details: false, Facilities: false, Our_Labs: false, Photo_Gallary: false, Enquiry: false, Contact_Us: false }
        case "Instructor_Details":
            return { ...state, Home: false, About_Us: false, Entrance_Exams: false, Our_Courses: false, Instructor_Details: true, Facilities: false, Our_Labs: false, Photo_Gallary: false, Enquiry: false, Contact_Us: false }
        case "Facilities":
            return { ...state, Home: false, About_Us: false, Entrance_Exams: false, Our_Courses: false, Instructor_Details: false, Facilities: true, Our_Labs: false, Photo_Gallary: false, Enquiry: false, Contact_Us: false }
        case "Our_Labs":
            return { ...state, Home: false, About_Us: false, Entrance_Exams: false, Our_Courses: false, Instructor_Details: false, Facilities: false, Our_Labs: true, Photo_Gallary: false, Enquiry: false, Contact_Us: false }
        case "Photo_Gallary":
            return { ...state, Home: false, About_Us: false, Entrance_Exams: false, Our_Courses: false, Instructor_Details: false, Facilities: false, Our_Labs: false, Photo_Gallary: true, Enquiry: false, Contact_Us: false }
        case "Enquiry":
            return { ...state, Home: false, About_Us: false, Entrance_Exams: false, Our_Courses: false, Instructor_Details: false, Facilities: false, Our_Labs: false, Photo_Gallary: false, Enquiry: true, Contact_Us: false }
        case "Contact_Us":
            return { ...state, Home: false, About_Us: false, Entrance_Exams: false, Our_Courses: false, Instructor_Details: false, Facilities: false, Our_Labs: false, Photo_Gallary: false, Enquiry: false, Contact_Us: true }
        default:
            return { ...state }
    }

}

export default function Navbar() {


    const [state, dispatch] = useReducer(reducer, {
        Home: true,
        About_Us: false,
        Entrance_Exams: false,
        Our_Courses: false,
        Instructor_Details: false,
        Facilities: false,
        Our_Labs: false,
        Photo_Gallary: false,
        Enquiry: false,
        Contact_Us: false
    })

    const { Home, About_Us, Entrance_Exams, Our_Courses,Instructor_Details, Facilities, Our_Labs, Photo_Gallary, Enquiry, Contact_Us } = state;


    return (
        <div >
            <Nav state={state} dispatch={dispatch} />
            <BackSlider />
            <div className="all-container">
                {/* {Home ? <HomeLower /> : ""} */}
                {Home ? <HomeLower state={state} dispatch={dispatch} /> : ""}
                {About_Us ? <About /> : ""}
                {Entrance_Exams ? "" : ""}
                {Our_Courses ? <OurCourses /> : ""}
                {Instructor_Details?<Instructor/>:""}
                {Facilities ? <Facility /> : ""}
                {Our_Labs ? <OurLabs /> : ""}
                {Photo_Gallary ? <PhotoGallery /> : ""}
                {Enquiry ? <EnquirySection /> : ""}
                {Contact_Us ? <ContactUs /> : ""}
            </div>

        </div>
    )
}
