const slideImages = [
    {
        url:"/images/Slider1.jpg"
    },
    {
        url:"/images/Slider2.jpg"
    },
    {
        url:"/images/Slider3.jpg"
    },
    {
        url:"/images/Slider4.jpg"
    },
]
export default slideImages;