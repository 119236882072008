import React from 'react'
import "./facilities.scss"
import Footer from '../Footer/Footer'
import {motion} from "framer-motion"
export default function Facility() {
    return (
        <div className='facilities' id='Facilities'>
            <h1 className='facility-heading'>Facilities</h1>
            <motion.div className="only-text-container" initial={{ y: -100, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>
                <h1 className='about-heading'>Library</h1>
                <div className="only-text">
                    <p>
                        We have digital library with more than 1500 books from various renewed international and national authors. The library also has access to various national and international nursing journals. Also daily Hindi and English News paper and magazines.
                    </p>

                </div>
            </motion.div>
            <motion.div className="only-text-container" initial={{ y: -100, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>
                <h1 className='about-heading'>Conveyance Facility</h1>
                <div className="only-text">
                    <p>
                        Institute provided bus facility for student and staff from different location of Baran
                    </p>

                </div>
            </motion.div>
            
            <motion.div className="only-text-container" initial={{ y: -100, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>

                <h1 className='about-heading'>Hostel and Canteen</h1>
                <div className="only-text">
                    <p>
                        Institute provides spacious, well furnished hostel facility for boys and girls
                    </p>

                </div>
            </motion.div>
            <motion.div className="only-text-container" initial={{ y: -100, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>

                <h1 className='about-heading'>Placement and Training Cell</h1>
                <div className="only-text">
                    <p>
                    I- Career
                    The institute designed for the student, who will face the campus interview for placement, presentation skills. Our salient aim is to prepare graduate nurse for abroad point of view.
                    </p>
                    <p>II- Opportunities</p>
                    <p>» Self Employment</p>
                    <p>» Private Placement»Government Jobs</p>
                    <p>» Overseas OpportunitiesFacility</p>
                   

                </div>
            </motion.div>
            <Footer/>
        </div>
    )
}
