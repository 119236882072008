import React, { useEffect, useState } from 'react';
import "./backSlider.scss";
import slideImages from "./SlideImages"
import SideList from '../Home/HomeUpperSection/subComponent/SideList';
export default function BackSlider() {
    const [current,setCurrent] = useState(0);

   
    useEffect(()=>{
        const timer = setTimeout(()=>{
            if(current===3){
                setCurrent(0)
            }
            else{
                setCurrent(current+1)
            }
        },4000)
    },[current])
  return (
    <div className='back-slider'>
        <div className="slider" style={{backgroundImage:`url(${slideImages[current].url})`}}></div>
        <div className="side-link-desc">
            {/* <SideList/> */}
        </div>
    </div>
  )
}
