import React from 'react'
import "./ourCourses.scss"
import {motion} from "framer-motion"
import Footer from '../Footer/Footer'
export default function OurCourses() {
    return (
        <div className='our-courses' id='OurCourses'>
            <motion.div className="only-text-container" initial={{ y: -100, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>
                <h1 className='about-heading'>Our Courses</h1>
                <div className="only-text">
                    <p>
                        We are running B.Sc. Nursing 4 year degree course.  &#40;Total number of Seats 40 &#41;. 50% total seats will be filled by Govt. of Rajasthan, Jaipur and 50% seats are management which are filled by federation.
                    </p>

                </div>
            </motion.div>
            <motion.div className="only-text-container" initial={{ y: -100, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>
                <h1 className='about-heading'>Eligibility Age Limit</h1>
                <div className="only-text">
                    <p>
                        The candidate should have completed 17 years of age on or before 31st December, of that session.
                    </p>

                </div>
            </motion.div>
            <motion.div className="only-text-container" initial={{ y: -100, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>
                <h1 className='about-heading'>Qualification</h1>
                <div className="only-text">
                    <p>
                        The qualification for selection is 12th standard with Bio Stream from Rajasthan board or an equivalent examination of any recognized board/University.
                    </p>
                    <p className='marks-eligiblity'> Eligibility of Marks</p>
                    <p>
                        A pass out in 10+2 system with 45% marks in could be in PCBE as per norms of RUHS Univ./INC. SC/ST candidates will get 5% relaxation of percentage. The candidate should be medically fit at the time of admission.
                    </p>

                </div>
            </motion.div>
            <motion.div className="only-text-container" initial={{ y: -100, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>
                <h1 className='about-heading'>Verification of the Document</h1>
                <div className="only-text">
                    <p>
                        » Two recent colour photograph.
                    </p>
                    <p>
                        » Secondary Mark Sheet & Certificate.
                    </p>
                    <p>
                        » Higher Secondary Mark Sheet & Certificate.
                    </p>
                    <p>
                        » Character Certificate from the Institution last attended.
                    </p>
                    <p>
                        » Bona Fide Certificate.
                    </p>
                    <p>» Cast Certificate.</p>
                    <p>» The candidate under physically handicapped category must bring their handicapped certificate showing his/her physical disability issued only by a statutory medical board Constituted by authority of any Goverment, medical College of Rajasthan.</p>



                </div>
            </motion.div>
            <motion.div className="only-text-container" initial={{ y: -100, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>
                <h1 className='about-heading'>Fee</h1>
                <div className="only-text">
                    <p>
                        As per the State Government Norms.
                    </p>

                </div>
            </motion.div>
            <Footer/>
        </div>
    )
}
