import React, { useReducer, useState } from 'react'
import "./enquiry.scss";
import {motion} from "framer-motion";
import Footer from '../Footer/Footer';
import axios from 'axios';
const reducer = (currentState,action)=>{
  switch(action.type){
  case "Success":
  return {...currentState,loading:false,success:true,error:""};
  case "Fails":
  return {...currentState,loading:false,success:false,error:action.payload};
  case "Request":
  return {...currentState,loading:true,success:false,error:""}

  default:
  return {...currentState};
}
}
export default function EnquirySection() {

  const [currentState, dispatch] = useReducer(reducer, {
    loading: false,
    success: false,
    error: "",
   
})
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [message, setMessage] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const{loading,success,error} = currentState;

  const baseUrl ="https://backendemail-8ibf.onrender.com";
  const handleSubmit = async (e) => {
    e.preventDefault()
    dispatch({type:"Request"});
    try{
      const { data } = await axios.post(`${baseUrl}/email`, {
        name: name,
        email: email,
        phone: phone,
        address: address,
        city: city,
        message: message,
        state: state,
        country: country,
      });
      // console.log(data)
      dispatch({type:"Success"});
      
    }catch(err){
      dispatch({type:"Fails",payload:err.message});
    }
 
  }
 
  return (
    <div>
      <motion.div className='enquiry' id='Enquiry' initial={{ y: -100, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>

        <div className="enquiry-email-image">
          <img src="/images/email.png" alt="" className="email-image" />
        </div>

        <div className="enquiry-title">Enquiry</div>

        <div className="enquiry-form">

          <form onSubmit={handleSubmit}>

            <div className="form-col-container">


              <div className="form-col-one">

                <div className="form-elements">
                  <label htmlFor='name'>Name : </label>
                  <input type='text' name="name" required placeholder='Enter Your Name' value={name} onChange={(e) => { setName(e.target.value); }}></input>
                </div>

                <div className="form-elements">
                  <label htmlFor='email'>Email : </label>
                  <input type='email' name="email" placeholder='Enter Your Email' required value={email} onChange={(e) => { setEmail(e.target.value) }}></input>
                </div>

                <div className="form-elements">
                  <label htmlFor='phone'>Phone : </label>
                  <input type='number' name="phone" required placeholder='Enter Your Phone Number' value={phone} onChange={(e) => { setPhone(e.target.value); }}></input>
                </div>

                <div className="form-elements">
                  <label htmlFor='address'>Address : </label>
                  <input type='text' name="address" required placeholder='Enter Your Address' value={address} onChange={(e) => { setAddress(e.target.value); }}></input>
                </div>

              </div>

              <div className="form-col-two">

                <div className="form-elements">
                  <label htmlFor='city'>City : </label>
                  <input type='text' name="city" required placeholder='Enter Your City' value={city} onChange={(e) => { setCity(e.target.value); }}></input>
                </div>

                <div className="form-elements">
                  <label htmlFor='state'>State : </label>
                  <input type='text' name="state" required placeholder='Enter Your State' value={state} onChange={(e) => { setState(e.target.value); }}></input>
                </div>

                <div className="form-elements">
                  <label htmlFor='country'>Country : </label>
                  <input type='text' name="country" required placeholder='Enter Your Country' value={country} onChange={(e) => { setCountry(e.target.value); }}></input>
                </div>

              </div>

            </div>

            <div className="form-elements">
              <label htmlFor='message' className='message-label'>Message </label>
              <textarea rows={4} required name='message'
                placeholder='Enter Your Message / Query' value={message}
                onChange={(e) => { setMessage(e.target.value) }}></textarea>
            </div>
            {loading && <div style={{display:"flex",flexDirction:"colomn",fontSize:"10px",color:"light-gray"}}><i class="fa-solid fa-spinner fa-spin-pulse"></i> Sending this email may take up to 50 seconds.</div>}
            {error?<p style={{color:"#f53535"}}>An error occured while sending this mail. </p>:""}
            {success?<p style={{color:"#57ac09"}}>Sucessfully sent</p>:""}
            <button type='submit' className='send-button'>Send</button>

          </form>

        </div>

      </motion.div>
     
      <Footer />
    </div>
  )
}
