export const photos = [
  {
    src: "/images/38.jpg",
    width: 3,
    height: 3
  },
  {
    src: "/images/39.jpg",
    width: 3,
    height: 3
  },
  {
    src: "/images/40.jpg",
    width: 3,
    height: 3
  },
  {
    src: "/images/41.jpg",
    width: 3,
    height: 3
  },
  {
    src: "/images/42.jpg",
    width: 3,
    height: 3
  },
  {
    src: "/images/43.jpg",
    width: 3,
    height: 3
  },
  {
    src: "/images/44.jpg",
    width: 3,
    height: 3
  },
  {
    src: "/images/45.jpg",
    width: 3,
    height: 3
  },
  {
    src: "/images/46.jpg",
    width: 3,
    height: 3
  },
  {
      src: "/images/1.jpeg",
      width: 3,
      height: 2
    },
    {
      src: "/images/2.jpeg",
      width: 1.5,
      height: 1
    },
    {
      src: "/images/3.jpeg",
      width: 4,
      height: 3
    },
    
    {
      src: "/images/5.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "/images/6.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "/images/7.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "/images/8.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "/images/9.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "/images/10.jpeg",
      width: 6,
      height: 3
    },
    {
      src: "/images/11.jpeg",
      width: 6,
      height: 3
    },
    {
      src: "/images/12.jpeg",
      width: 6,
      height: 3
    },
    {
      src: "/images/13.jpg",
      width: 2,
      height: 3
    },
    {
      src: "/images/14.jpg",
      width: 5,
      height: 2
    },
    {
      src: "/images/15.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/images/16.jpg",
      width: 6,
      height: 3
    },
    {
      src: "/images/17.jpg",
      width: 5,
      height: 3
    },
    {
      src: "/images/18.jpg",
      width: 6,
      height: 3
    },
    {
      src: "/images/19.jpeg",
      width: 4,
      height: 6
    },
    {
      src: "/images/20.jpeg",
      width: 6,
      height: 4
    },
    {
      src: "/images/21.jpeg",
      width: 6,
      height: 4
    },
  

    {
      src: "/images/23.jpeg",
      width: 6,
      height: 4
    },
    {
      src: "/images/24.jpeg",
      width: 5,
      height: 3
    },
    {
      src: "/images/25.jpeg",
      width: 6,
      height: 4
    },
    {
      src: "/images/26.jpeg",
      width: 6,
      height: 4
    },
    {
      src: "/images/27.jpeg",
      width: 6,
      height: 4
    },
    {
      src: "/images/28.jpeg",
      width: 6,
      height: 4
    },
    {
      src: "/images/29.jpeg",
      width: 6,
      height: 4
    },
    {
      src: "/images/30.jpeg",
      width: 6,
      height: 2
    },
    {
      src: "/images/31.jpeg",
      width: 6,
      height: 4
    },
   
    
    
    
    
    
  ];