import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import "./instructor.scss";
import Footer from '../Footer/Footer';
function createData(Name, Qualification, Image) {
  return { Name, Qualification, Image };
}

const rows = [
  createData('ASHISH KUMAR', "M.SC. NURSING", "/images/I1.jpg"),
  createData('HEMANT NAGAR', "B.Sc. NURSING", "/images/I3.jpg"),
  createData('RAHUL GALAV', "B.SC. NURSING", "/images/I4.jpg"),
  createData('KESHARI LAL', "M.Sc. NURSING", "/images/I6.jpg"),
  createData('GAGAN YADAV', "M.SC. NURSING", "/images/I7.jpg"),
  createData('DINESH KUMAR NAGAR', "M.Sc. NURSING	", "/images/I8.jpg"),
  createData('MAHESH KUMAR SHARMA', "M.Sc. NURSING", "/images/I9.jpg"),
  createData('PRIYANSHU SHARMA', "M.Sc. NURSING", "/images/I10.jpg"),
  createData('RAMCHARAN SAKYWAL', "B.Sc. NURSING	", "/images/I11.jpg"),
  createData('LOKESH KUMAR SUMAN', "B.SC. NURSING", "/images/I13.jpg"),

  createData('Dhanraj Suman', "B.SC. NURSING", "/images/I15.jpeg"),
  createData('Alkesh Nagar', "B.SC. NURSING", "/images/I16.jpeg"),
  createData('Kaushal Kishore Nagar', "B.SC. NURSING", "/images/I17.jpeg"),
  createData('Ashish Sharma', "B.SC. NURSING", "/images/I18.jpeg"),
  createData('Tripti Nagar', "B.SC. NURSING", "/images/I19.jpeg"),
  createData('Pooja Nagar', "B.SC. NURSING", "/images/I20.jpeg"),
  createData('Mr. Jodhraj Nagar', "M.Sc. NURSING", "/images/I21.jpeg"),
  createData('Mrs. Anupriya Malav', "M.Sc. NURSING", "/images/I22.jpeg"),
  
 

  // createData('MRS. VANDANA SHEKHAWAT', "M.SC. NURSING", "/images/I15.jpg"),
];

export default function Instructor() {
  return (
    <div className="instructor-details" id="Instructor">
      <h1 className="instructor-head">Instructor Details</h1>
      <div className="table">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 550 }} aria-label="simple table">
            <TableHead style={{ backgroundColor: "#3886CE", color: "white" }}>
              <TableRow>
                <TableCell align="center" style={{ color: "white", fontFamily: "Inter-Medium", fontSize: "16px" }}>Name</TableCell>
                <TableCell align="center" style={{ color: "white", fontFamily: "Inter-Medium", fontSize: "16px" }}>Qualification</TableCell>
                <TableCell align="center" style={{ color: "white", fontFamily: "Inter-Medium", fontSize: "16px" }}>Image</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="center" style={{ color: "grey", fontFamily: "Inter-Medium", fontSize: "18px" }}>
                    {row.Name}
                  </TableCell>
                  <TableCell align="center" style={{ color: "grey", fontFamily: "Inter-Medium", fontSize: "18px" }}>{row.Qualification}</TableCell>
                  <TableCell align="center" style={{ color: "grey", fontFamily: "Inter-Medium", fontSize: "18px" }}><img src={row.Image} alt="Error" className="insturctor-image" style={{ maxWidth: "200px", maxHeight: "200px" }} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        
      </div>
      <Footer />
    </div>

  );
}