import React from 'react'
import "./contactUs.scss"
import { motion } from "framer-motion"
import Footer from '../Footer/Footer'
export default function ContactUs() {
    return (
        <div className='contact-us' id='Contact'>
            <motion.div className="only-text-container" initial={{ y: -100, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>
                <h1 className='about-heading'>Contact Us</h1>
                <div className="only-text">
                    <p>Shri Onkar Lal Nursing Mahavidyalay<br></br>Sangod Road, Gajanpura <br /> Baran, Rajasthan, India - 325205</p>
                    <p>Cell No.: +91-9414662830 / +91-9414190229</p>
                    <p>E-mail Id:<a href="mailto:solnursing@gmail.com"> solnursing@gmail.com</a></p>
                    <p>Website: <a href="http://www.solnursing.com" target="_blank" rel="noopener noreferrer">www.solnursing.com</a> </p>
                </div>
            </motion.div>
            <Footer />
        </div>
    )
}
