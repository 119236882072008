import React, { useState } from 'react'
import { motion } from "framer-motion"
import { HashLink } from 'react-router-hash-link';
import TopLogo from '../TopLogo/TopLogo';
export default function Nav({state,dispatch}) {

    const { Home, About_Us, Entrance_Exams, Our_Courses, Facilities, Our_Labs,Instructor_Details,Photo_Gallary, Enquiry, Contact_Us } = state;
    const [isOpen, setIsOpen] = useState(false);
    const handleToggle = () => {
        setIsOpen(!isOpen);
        console.log(isOpen)
    }
    const navOpen = {
        closed: {
            clipPath: "rectangle(200px at 0px 0px)",
            transition: {
                type: "spring",
                stiffness: 100,
                damping: 30,
                duration: 3.1,
            },
        },

        open: {
            clipPath: "circle(1900px at 401px 416px)",
            transition: {
                type: "spring",
                stiffness: 20,
                duration: 3,
                damping: 20,


            }

        },
        opacity: 0
    }
    const varients = {
        initial:{x:0,opacity:0,scale:0},
        animate:{x:0,opacity:1,scale:1,transition:{duration:1,straggerChildres:0.3}}
    }
    return (
        <div>
            <TopLogo state={state} dispatch={dispatch}/>
            <motion.div className={isOpen ? "navbar" : "navbar collapse"}  >
                <motion.div className="nav-links" variants={varients} initial="initial" animate="animate">
                    <motion.div className="hamburger-container"> <img src='/images/hamburger.svg' alt='Error' className='hamburger' onClick={handleToggle} initial={{ scale: 0, opacity: 0 }} animate={{ scale: 1, opacity: 1, transition: { duration: 2 } }}></img></motion.div>
                    <motion.ul variants={navOpen} animate={isOpen ? "open" : "closed"} >

                        <motion.div variants={varients} className='nav-height' >
                            <HashLink scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: 'start' })} to="#Home">
                                <motion.li className={Home ? "active-nav-link" : ""}
                                    onClick={() => {
                                        dispatch({ type: "Home" });
                                        setIsOpen(false);
                                    }}>Home</motion.li>
                            </HashLink>
                        </motion.div>



                        <HashLink scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: 'start' })} to="#About">
                            <li className={About_Us ? "active-nav-link" : ""} onClick={() => {
                                dispatch({ type: "About_Us" });
                                setIsOpen(false);
                            }}>About Us</li>
                        </HashLink>

                        <HashLink scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: 'start' })} to="#Exam">
                            <li className={Entrance_Exams ? "active-nav-link" : ""} onClick={() => {
                                dispatch({ type: "Entrance_Exams" });
                                setIsOpen(false);
                            }}>Entrance Exams</li>
                        </HashLink>

                        <HashLink scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: 'start' })} to="#OurCourses">
                            <li className={Our_Courses ? "active-nav-link" : ""} onClick={() => {
                                dispatch({ type: "Our_Courses" });
                                setIsOpen(false);
                            }}>Our Courses</li>
                        </HashLink>

                        <HashLink scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: 'start' })} to="#Instructor">
                            <li className={Instructor_Details ? "active-nav-link" : ""} onClick={() => {
                                dispatch({ type: "Instructor_Details" });
                                setIsOpen(false);
                            }}>Instructor Details</li>
                        </HashLink>
                        <HashLink scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: 'start' })} to="#Facilities">
                            <li className={Facilities ? "active-nav-link" : ""} onClick={() => {
                                dispatch({ type: "Facilities" });
                                setIsOpen(false);
                            }}>Facilities</li>
                        </HashLink>

                        <HashLink scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: 'start' })} to="#OurLabs">
                            <li className={Our_Labs ? "active-nav-link" : ""} onClick={() => {
                                dispatch({ type: "Our_Labs" });
                                setIsOpen(false);
                            }}>Our Labs</li>
                        </HashLink>

                        {/* <HashLink scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: 'start' })} to="#Gallery">
                            <li className={Photo_Gallary ? "active-nav-link" : ""} onClick={() => {
                                dispatch({ type: "Photo_Gallary" });
                                setIsOpen(false);
                            }}>Photo Gallary</li>
                        </HashLink> */}


                        <HashLink scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: 'start' })} to="#Contact">
                            <li className={Contact_Us ? "active-nav-link" : ""} onClick={() => {
                                dispatch({ type: "Contact_Us" });
                                setIsOpen(false);
                            }}>Contact Us</li>
                        </HashLink>

                    </motion.ul>
                </motion.div>
            </motion.div>
        </div>
    )
}
