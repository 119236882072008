import logo from './logo.svg';
import './App.scss';
import HomeUpper from './Components/Home/HomeUpperSection/HomeUpper';
import Navbar from './Components/Nav/Navbar';
import { BrowserRouter, NavLink, Router, Route } from "react-router-dom";
import BackSlider from './Components/Top/BackSlider';
import TopLogo from './Components/TopLogo/TopLogo';
import Nav from './Components/Nav/Nav';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        {/* <HomeUpper /> */}
      
        
        
        <Navbar />
      </div>
    </BrowserRouter>
  );
}

export default App;
