import React from 'react'
import "./about.scss"
import { motion } from "framer-motion";
import Footer from '../Footer/Footer'
export default function About() {
    return (
        <div className='about' id='About'>
            <motion.div className="only-text-container" initial={{ y: -200, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>
                <h1 className='about-heading'>About Us</h1>
                <div className="only-text">
                    <p>
                        Established in the 2009 "Shri Onkar Lal Nursing Mahavidhyalya " is recognized by Rajasthan Government, Rajasthan Nursing Council &#40;R.N.C&#41; & Indian Nursing Council  &#40;I.N.C&#41;  New Delhi and affiliated to Rajasthan University of Health Sciences &#40;R.U.H.S.&#41;, Jaipur.
                    </p>
                    <p>
                        The institute runs itself owned modernly designed building. It includes well ventilated and furnished classrooms, well equipped laboratories, library, A.V. Aids rooms, Director's, Principal and Teaching Staff rooms. The working team includes trained and qualified teaching staff, clinical instructors & administrative staff.
                    </p>
                    <p>
                        The faculty believes in providing nursing education based and builds on scientific principles in order to produce professionally qualified nurses who will be able to work as team member in all health care agencies. The institute provides and promotes all round development of the students with a vision which is needed to prepare professional nurses, who will contribute as "Changing Agent" in an ever changing society.
                    </p>
                </div>
            </motion.div>
            <motion.div className="only-text-container" initial={{ y: -300, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>
                <h1 className='about-heading'>Mission </h1>
                <div className="only-text">
                    <p>
                        The aim of institute is to provide education to foster appropriate attitudes, skills required for professional nurses and promote research in nursing field to produce highly competent and versatile professionals who will participate in comprehensive development and growth of nursing and also to establish this institute as apex of nursing education.
                    </p>

                </div>
            </motion.div>
           
            <motion.div className="only-text-container" initial={{ y: -300, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>
                <h1 className='about-heading'> Our Hospital</h1>
                <div className="only-text">
                    <p>
                        Our institute has a parental hospital named Zonal Hospital Baran, Rajasthan, India. That is 100 bedded multi specialty hospitals with OPD facilities and casualty various type of works. We have a team of eminent M.D. and M.S. these there duties carefully.
                    </p>

                </div>
            </motion.div>
            <Footer />
        </div>
    )
}
