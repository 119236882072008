import React from 'react'
import "./topLogo.scss"
import { HashLink } from 'react-router-hash-link'
export default function TopLogo({ state, dispatch }) {
    return (
        <div className='top-logo' id='Top'>
            <div className="home-grid-one">
                <div className="institute-logo">
                    <img src="/images/Logo-image.png" alt="Error" className="logo" />
                </div>
                <div className="institute-name">Shri Onkarlal Nursing Mahavidhyalya</div>
            </div>
            <div className="top-side-button-cotainer">
                <div className="top-side-button">
                    <HashLink scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: 'start' })} to="#Gallery">
                        <button className="image-gallery"
                            onClick={() => {
                                dispatch({ type: "Photo_Gallary" });
                            }}>
                            Photo Gallery
                             &nbsp;<i class="fa-regular fa-image" style={{ color: " #223249;" }}></i>
                        </button>
                    </HashLink>
                </div>
                <div className="top-side-button">
                    <HashLink scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: 'start' })} to="#Enquiry">
                        <button className="top-enqiry"
                            onClick={() => {
                                dispatch({ type: "Enquiry" });
                            }}>
                            Enquiry
                            &nbsp;<i class="fa-solid fa-paper-plane fa-rotate-by" style={{ color: "#263450; --fa-rotate-angle: 0deg;" }}></i>
                        </button>
                    </HashLink>
                </div>
            </div>
        </div>
    )
}
