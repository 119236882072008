import React from 'react'
import "./photoGallery.scss"
import Test from './Test'
export default function PhotoGallery() {
  return (
    <div className='photo-gallery' >
      <div className="only-text-container">
        <h1 className='about-heading' >Photo Gallery</h1>
        <div className="only-text" id='Gallery'>

          {/* <p><img src="/images/1.jpeg" alt="error" className='gallery-images'/></p>
                    <p><img src="/images/2.jpeg" alt="error" className='gallery-images'/></p>
                    <p><img src="/images/3.jpeg" alt="error" className='gallery-images'/></p>
                    <p><img src="/images/4.jpeg" alt="error" className='gallery-images'/></p>
                    <p><img src="/images/5.jpeg" alt="error" className='gallery-images'/></p>
                    <p><img src="/images/6.jpeg" alt="error" className='gallery-images'/></p>
                    <p><img src="/images/7.jpeg" alt="error" className='gallery-images'/></p>
                    <p><img src="/images/8.jpeg" alt="error" className='gallery-images'/></p>
                    <p><img src="/images/9.jpeg" alt="error" className='gallery-images'/></p>
                    <p><img src="/images/10.jpeg" alt="error" className='gallery-images'/></p>
                    <p><img src="/images/11.jpeg" alt="error" className='gallery-images'/></p>
                    <p><img src="/images/12.jpeg" alt="error" className='gallery-images'/></p>
                    <p><img src="/images/13.jpg" alt="error" className='gallery-images'/></p>
                    <p><img src="/images/14.jpg" alt="error" className='gallery-images'/></p>
                    <p><img src="/images/15.jpg" alt="error" className='gallery-images'/></p>
                    <p><img src="/images/16.jpg" alt="error" className='gallery-images'/></p>
                    <p><img src="/images/17.jpg" alt="error" className='gallery-images'/></p>
                    <p><img src="/images/18.jpg" alt="error" className='gallery-images'/></p> */}

          <Test />
        </div>
      </div>
    </div>
  )
}
