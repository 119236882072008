import React, { useState } from 'react'
import "./homeLower.scss"
import Footer from '../../Footer/Footer'
import { motion } from 'framer-motion'
import { HashLink } from 'react-router-hash-link';
export default function HomeLower({ state, dispatch }) {
    const [readMore, setReadMore] = useState(true);

    return (
        <div className='home-lower-container' id='Home'>
            <div className="intro-section">
                <h1 className='home-about-us'>About Us</h1>
                <div className="text-readmore-container">
                    <motion.div className="text-container" initial={{ x: -200, scale: 1, opacity: 0 }} whileInView={{ x: 0, scale: 1, opacity: 1, transition: { duration: 1 } }}>
                        Established in the 2009 "Shri Onkar Lal Nursing Mahavidhyalya" is recognized by Rajasthan Government, Rajasthan Nursing Council &#40;R.N.C&#41; & Indian Nursing Council  &#40;I.N.C&#41;  New Delhi and affiliated to Rajasthan University of Health Sciences &#40;R.U.H.S.&#41;, Jaipur.
                        <br /><br />
                        The institute runs itself owned modernly designed building. It includes well ventilated and furnished classrooms, well equipped laboratories, library, A.V. Aids rooms, Director's, Principal and Teaching Staff rooms. The working team includes trained and qualified teaching staff, clinical instructors & administrative staff.
                        <br /><br />
                        The faculty believes in providing nursing education based and builds on scientific principles in order to produce professionally qualified nurses who will be able to work as team member in all health care agencies. The institute provides and promotes all round development of the students with a vision which is needed to prepare professional nurses, who will contribute as "Changing Agent" in an ever changing society.
                    </motion.div>


                    <p className='link'>
                        <HashLink scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: 'start' })} to="#About" onClick={() => {
                            dispatch({ type: "About_Us" });
                        }}>read more...</HashLink>
                    </p>
                </div>
                {/* <motion.div className="image-container" initial={{scale:0.9,opacity:0}} whileInView={{scale:1,opacity:1, transition:{duration:0.8}}}>
                    <span className="image-overlay-text"><span className='image-sentence'>Practical classes in mahavidhyalya of school students</span><span className='image-read-more'><a href='#/'>read more...</a></span></span>
                    <h2 className="news-intro--image-heading">News and Events</h2>
                    <img src="/images/IntroImage.svg" alt="Error" className="intro-image" />
                </motion.div> */}
            </div>
            <div className="director-desk-container">
                <motion.div className="director-desk" initial={{ x: -300, scale: 1, opacity: 0 }} whileInView={{ x: 0, scale: 1, opacity: 1, transition: { duration: 1 } }}>
                    <div className="director-desk-image">
                        <img src="/images/directorDeskImage.svg" alt="Error" className="image" />
                    </div>
                    <div className="director-desk-text">
                        <div className="director-desk-heading">
                            Director Desk
                        </div>
                        <p className="content">
                            {readMore ? <> The nursing programme is an integral part of the health science&#39;s education. The nursing programme prepares culturally diverse students..</> : <>The nursing programme is an integral part of the health science's education. The nursing programme prepares culturally diverse students in a safe and supportive environment that educate people for responsible membership in an ever-changing technological society. We believe that professional nursing is a science and an art that provides holistic care aimed at assisting or empowering individuals, family's communities to achieve health. To meet the demand of acute shortage of trained and qualified graduate nurses not only in Rajasthan but also in India, we took up the challenge by establishing "Shri Onkar Lal Nursing College" for imparting B.Sc. Nursing- 4 Year Degree Course. Being a part of our institution, you will be academically and culturally enriched and prosper in your life.</>}
                        </p>
                        <p className='link'><a href='#/' onClick={() => { setReadMore(!readMore) }}>{readMore ? <>read more...</> : <>read less...</>}</a></p>
                    </div>
                </motion.div>
                {/* <motion.div className="director-desk" initial={{ x: -300, scale: 1, opacity: 0 }} whileInView={{ x: 0, scale: 1, opacity: 1, transition: { duration: 1 } }}>
                    <div className="director-desk-image">
                        <img src="/images/aboutHospital.svg" alt="Error" className="image" />
                    </div>
                    <div className="director-desk-text">
                        <div className="director-desk-heading">
                            About Hospital
                        </div>
                        <p className="content">
                            Our institute has a parental hospital named Zonal Hospital Baran, Rajasthan, India. That is 100 bedded multi specialty hospitals...
                        </p>
                        <p className='link'><a href='#/'>read more...</a></p>
                    </div>
                </motion.div> */}

            </div>

            <div className="owner-Desk-container">
                <div className="owner-desk-heading">
                    Owners Desk
                </div>
                <div className="owner-info">
                    <motion.div className="owner-one" initial={{ x: 0, scale: 0.9, opacity: 0.6 }} whileInView={{ x: 0, scale: 1, opacity: 1, transition: { duration: 0.8 } }}>
                        <img src="/images/Owner1.svg" alt="Error" className="owner-image" />
                        <div className="owner-title"><h2>N.K NAGAR</h2><span>&#40;Chairman&#41;</span></div>
                    </motion.div>
                    <motion.div className="owner-one" initial={{ x: 0, scale: 0.9, opacity: 0.6 }} whileInView={{ x: 0, scale: 1, opacity: 1, transition: { duration: 0.8 } }}>
                        <img src="/images/Owner2.svg" alt="Error" className="owner-image" />
                        <div className="owner-title"><h2>B.R NAGAR</h2><span>&#40;Director&#41;</span></div>
                    </motion.div>
                </div>
                <Footer />
            </div>
        </div>
    )
}
