import React from 'react'
import "./ourLabs.scss";
import {motion} from "framer-motion"
import Footer from "../Footer/Footer"
export default function OurLabs() {
    return (
        <div className='our-labs' id='OurLabs'>
        <h1 className='facility-heading'>Our Labs</h1>
         <motion.div className="only-text-container" initial={{ y: -100, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>
                <h1 className='about-heading'>Fundamental of Nursing Lab</h1>
                <div className="only-text">
                    <p>
                    This is the most primarily and essential lab. Here student are given practical insights through training which incorporates various biological and behavioral science that determine nursing and patient care. The fundamental nursing laboratory is the place where students get practical orientation of various nursing procedures. These is conducted in keep supervision by our qualified teacher. These procedures are repeated many times before orientation in the clinical area to make students fully acquaint with nursing procedures. The laboratory has advanced facilities that include computerized mannequin used to stimulate nursing procedures. This lab seeds features and salient characteristics of nursing learning student which will make our nursing profession more sound and vital. It comprises all the equipments and apparatus, which the students are going to use in their clinical settings.
                    </p>

                </div>
            </motion.div>
            <motion.div className="only-text-container" initial={{ y: -100, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>
                <h1 className='about-heading'>Anatomy and Physiology Lab</h1>
                <div className="only-text">
                    <p>
                    The uniqueness of lab is that it is well equipped with all kinds of demonstration items. Like original body organs specimens, models and chart of organs and system and original articulated bones sets and a human skeleton etc. this subject helps the student to form the foundation by which they can learn and understand the subjects comfortably, it provides basic knowledge pertaining to our organs systems and facilities in understanding of every disease of organ of our body thus helps in correct diagnosis of diseases and ensure prompt recovery of patients.
                    </p>

                </div>
            </motion.div >
            <motion.div className="only-text-container" initial={{ y: -100, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>
                <h1 className='about-heading'>Micro Biology & Bio Chemistry Lab</h1>
                <div className="only-text">
                    <p>
                    This lab is very keen and unique as well as any other labs of this nursing institute. This lab is very salient lab in comprehensive understanding of any fundamental body reactions and body processes or disease progresses. It is well equipped and sophisticated number of microscopes, incubators, ovens, autoclave and slides of different species of microbes and charts. In this lab, various blood tests, urine test, protein, Albumin and all other tests are performed to acquaint the students about various diagnostic procedures under the guidance of experience faculty.
                    </p>

                </div>
            </motion.div >
            <motion.div className="only-text-container" initial={{ y: -100, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>
                <h1 className='about-heading'>Community and Midwifery lab &#40;A-Community Health Nursing Lab&#41;</h1>
                <div className="only-text">
                    <p>
                    Community health nursing has an important role in providing a complete nursing care to the individual and community. The role of community health nurse is indeed challenging. This department prepares students to undertake the socially conscious task of providing health care to both rural and urban communities. Student are taught to recognize the importance of health, prevention of disease and promotion of positive health in community setting. An experienced staff provides excellent theoretical and practical orientation to students in lab and fields setting. They are guided to provide care to the individuals families, communities at work place by incorporation with epidemiological principle o prevent disease, promote and restore health. Students also participate in government programmes like Pulse Polio Immunization.
                    </p>

                </div>
            </motion.div >
            <motion.div className="only-text-container" initial={{ y: -100, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>
                <h1 className='about-heading'>B-Midwifery Lab:</h1>
                <div className="only-text">
                    <p>
                    Here the role of faculties is to teach the students to work as midwife. For that all the skills necessary to administer nursing care to expectant mothers through the maternity cycle models and process of labor are taught. An understanding of the female reproductive system enables them to provide comprehensive care to women who are prone to high risk during pregnancy. It helps in building the healthy citizens of future. It provides basic information about drugs, instruments and procedures. It provides knowledge about car of both mother and children in critical cases. It has high quality of charts and Models showing various stages of labor.
                    </p>

                </div>
            </motion.div >
            <motion.div className="only-text-container" initial={{ y: -100, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>
                <h1 className='about-heading'>Nutrition Lab:</h1>
                <div className="only-text">
                    <p>
                    Nutrition is yet another critical are in nursing. Here students are taught the fundamentals of preparing various types of therapeutic diets. They learn to differentiate between a wide variety of diet patterns recommended for ailments such as diabetes, renal, problems, hypertension etc. which enable them to guide the patients with regards to their specific dietary requirements. The Nutrition Laboratory has every facility required to demonstrate, prepare and deliver various therapeutic diets. It is well equipped with all the required items.
                    </p>

                </div>
            </motion.div >
            <motion.div className="only-text-container" initial={{ y: -100, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>
                <h1 className='about-heading'>A. V. Aids Lab:</h1>
                <div className="only-text">
                    <p>
                    This lab is occupied with multimedia items like LCD Projectors, Slide Projectors, Epidiascopes, many OHP's with good quality of Transparencies, T.V., Tape recorder, VCR, DVD's and CD Players with CD - Rom. It also consists of good number of charts and models all these amenities are mandatory for empirical and permanent learning of the students.
                    </p>

                </div>
            </motion.div >
            <motion.div className="only-text-container" initial={{ y: -100, scale: 1, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1, transition: { duration: 1, damping: 10 } }}>
                <h1 className='about-heading'>Computer Lab:</h1>
                <div className="only-text">
                    <p>
                    The institute has well equipped and modernize computer lab with latest configuration and internet connectivity to all.
                    </p>

                </div>
            </motion.div >
        <Footer />
    </div >
  )
}
